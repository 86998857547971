<script setup>
const {t} = useI18n()
const router = useRouter()
const localePath = useLocalePath()
const {generators, ticketTypeQuantity} = useEventFormatter(t)
const cartStore = useCartStore()

const cart = computed(() => {
  return cartStore.cartItems
})

const cartItemCount = computed(() => {
  return cartStore.cartItemCount || 0
})

const cartTicketsCount = computed(() => {
  return cartStore.cartTicketsCount || 0
})

const cartTotal = computed(() => {
  return currencySymbolValue(cartStore.cartTotal, cartStore.currencySymbol) || 0
})

const formattedEvents = computed(() => {
  if (!cart.value) return []

  return cart.value.map(({event, session, tickets}) => {
    return {
      event: event,
      image: event.image_url,
      title: event.name,
      subtitles: [generators.venue(event.venue_name), session && session.label],
    }
  })
})

const openCartDropdown = () => {
  const dropdown = document.getElementById('cart-dropdown')
  const bsDropdown = new bootstrap.Dropdown(dropdown)
  bsDropdown.show()
}

const closeCartDropdown = () => {
  const dropdown = document.getElementById('cart-dropdown')
  const bsDropdown = new bootstrap.Dropdown(dropdown)
  bsDropdown.hide()
}

const goToCart = () => {
  closeCartDropdown()
  router.push(localePath({name: 'checkout-cart'}))
}

watch(
  () => cartStore.showCartDropdown,
  (val) => {
    if (val) {
      openCartDropdown()

      setTimeout(() => {
        cartStore.showCartDropdown = false
        closeCartDropdown()
      }, 3000)
    }
  },
)
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-cart.css';
</style>

<template>
  <div class="nu-cart">
    <span
      id="cart-dropdown"
      aria-expanded="false"
      class="nu-cart-icon active"
      data-bs-auto-close="outside"
      data-bs-toggle="dropdown"
      role="button"
    >
      <img
        alt="cart"
        class="cart-img"
        src="/images/common/cart.svg"
        width="22"
      />
      <img
        alt="cart"
        class="cart-img-active"
        src="/images/common/cart-active.svg"
        width="22"
      />
      <span
        class="cart-count"
        v-show="cartItemCount > 0"
      >
        {{ cartItemCount }}
      </span>
    </span>
    <div
      aria-labelledby="cart-dropdown"
      class="cart-dropdown-wrapper dropdown-menu"
    >
      <div class="cart-items">
        <NuWideEvent
          :event="event"
          infoPosition="top"
          :key="`event-item-${index}`"
          v-for="(event, index) in formattedEvents"
        />

        <div
          class="no-cart-items"
          v-if="formattedEvents.length === 0"
        >
          <img
            class="no-cart-items-icon"
            src="/images/event-icons/shopping-cart-solid.svg"
          />
          <p class="no-cart-items-text">{{ $t('comp_cart.no_cart_items') }}</p>
        </div>
      </div>
      <div class="cart-total-section">
        <div
          class="cart-sub-total"
          v-if="formattedEvents.length > 0"
        >
          <p class="cart-sub-total-heading">
            {{ t('comp_cart.subtotal', cartTicketsCount, {count: cartTicketsCount}) }}
          </p>
          <p class="cart-sub-total-price">{{ cartTotal }}</p>
        </div>
        <NuButton
          :class="{'w-100': formattedEvents.length === 0}"
          :display="'primary'"
          class="go-to-cart"
          @click="goToCart"
        >
          {{ $t('comp_cart.go_to_cart') }}
        </NuButton>
      </div>
    </div>
  </div>
</template>
